// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

@import "../src/views/MemberModule/Scss/MemberForm.scss";

.dataTables_filter {
  float: right;
}

.dataTables_paginate {
  float: right;
}

.sidebar .nav-dropdown-toggle::before {
  content: "\f078";
  font-family: FontAwesome;
  top: 39%;
  right: 0.9rem;
  background-image: none;
}

.sidebar .nav-link:hover.nav-dropdown-toggle::before {
  content: "\f078";
  font-family: FontAwesome;
  top: 39%;
  right: 0.9rem;
  background-image: none;
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  content: "\f077";
  font-family: FontAwesome;
  top: 39%;
  right: 0.9rem;
  background-image: none;
  transform: none;
}

.sidebar .nav-link {
  padding-left: 0.5em;
}

#memberFileUpload {
  .form-control-file {
    display: inline-block !important;
    width: 30% !important;
  }
}

// CUSTOM STYLES

.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}
