.debitby-remove-icon-0,
.creditby-remove-icon-0 {
  visibility: hidden;
} // Here you can add other styles

.remove-entry-icon {
  background-color: #df4a4a;
  padding: 7px 12px;
  border: unset;
  margin-bottom: 17px;
  font-weight: bold;
  color: white;
  border-radius: 0.25rem;
}

.monthly-recover-add-btn {
  padding: 10px 20px;
}

.monthly-recover-modal {
  max-width: 80%;
}

.custom-cursor-pointer {
  cursor: pointer;
}

.recovery-sheet-uncheck-member {
  background-color: #fce5eac9;
}

.recovery-sheet-edit-member {
  background-color: #ffffd8;
}

.recovery-table th {
  padding: 5px;
}
