#employeeForms{
  .btn-dark{
    border:1px solid #737a82 !important;
    background-color: #737a82 !important;
    padding: 2px 5px;
    margin: 0 0 0 5px;
  }
  .empRecords{
    float:right;
    text-transform: unset;
  }
  #footerButton{
    .btn-dark{
      margin:6px;
    }
  }
  // Button{
  //   width:10em;
  // }
}

#isInvalid  {
  border:1px solid red;
}

.showErrorMsg{
  display: block;
  color:red;
}